import axios from "axios"
import { z } from "zod"
import { getAuthorizedConfig } from "../../helpers/helpers"
import { DiscountTypesEnum } from "helpers/constants"
import { BookingItem } from "./bookings"

export const discount = z.object({
  id: z.number().optional(),
  activityId: z.number(),
  discountType: z.enum([DiscountTypesEnum.PERCENTAGE, DiscountTypesEnum.SUBSTRACTION]),
  discountNumber: z.number(),
  code: z.string(),
  isCseDiscount: z.boolean(),
  maxUsage: z.number().optional(),
  nbOfUsages: z.number().optional(),
  message: z.string().optional(),
  isPinned: z.boolean().optional(),
  lastDayDiscount: z.number().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  minBasket: z.number().optional(),
  maxUseAmount: z.number().optional(),
  maxUseTickets: z.number().optional()
})

export type Discount = z.infer<typeof discount>

export async function getDiscount({
  code,
  activityId,
  bookings
}: {
  code: string
  activityId: string | number
  bookings?: BookingItem[]
}): Promise<Discount> {
  const config = getAuthorizedConfig("POST")
  config["url"] = `${process.env.REACT_APP_API_URL}/discounts/${code}/activity/${activityId}`
  config["data"] = {bookings}
  const response = await axios.request(config)
  const result = response.data
  return result
}
