import "./Poweredby.style.css"
import { Link } from "react-router-dom"
import Logo from "../../images/logo.png"
import { defaultPoweredByColor, hopleisureWebsiteUrl } from "./Poweredby.constants"

const Poweredby = (props: { color: string | undefined }) => {
  const { color } = props

  return (
    <Link
      className="poweredby-container"
      to={hopleisureWebsiteUrl}
      target="_blank"
      rel="noopener noreferrer"
      title={hopleisureWebsiteUrl}
    >
      <span style={{ color: color ?? defaultPoweredByColor }}>Powered By</span>
      <img src={Logo} alt="hopleisure-logo" className="powered-by-logo" />
    </Link>
  )
}

export default Poweredby
