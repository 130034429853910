import React, { Dispatch, Fragment, SetStateAction, useEffect, useMemo, useState } from "react"
import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import TextField from "@mui/material/TextField"
import { useTranslation } from "react-i18next"
import { Button, Column, Title } from "styled_components"
import StripePayment from "./StripePayment"
import Discount from "./Discount"
import { Order } from "types/order.type"
import { Discount as DiscountType } from "backend/api/discounts"
import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import PaymentSuccessfull from "./paymentSuccessfull/PaymentSuccessfull"
import { getBookingsPrice } from "backend/api/bookings"
import { Activity } from "backend/api/activities"
import { ActivityTypeEnum } from "helpers/constants"
import { capitalize, isNumber } from "lodash"
import { schemaWithTranslation } from "./PaymentForm.types"
import { isArrayEmpty } from "helpers/helpers"
import "./PaymentForm.style.css"
import useWidget from "hooks/useWidget"
import { ExtraInfosType } from "types/widget.type"
import useLoginGoogle from "hooks/useLoginGoogle"
import GoogleIcon from "@mui/icons-material/Google"
import { Typography } from "@mui/material"

export interface PaymentForm {
  name: string
  email: string
  phoneNumber: string
  promotionCode: string
}

interface Props {
  order: Order
  stripeAccountId: string
  setDiscounts: Dispatch<SetStateAction<DiscountType[]>>
  discounts: DiscountType[]
  activity: Activity
  handleBack: () => void
}

function StripeWrapper({ accountId, children }: { accountId: string; children: any }) {
  const [stripeObject, setStripeObject] = useState<any>(null)

  // This function will re-run if the accountId prop changes.
  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (accountId) {
        const res = await loadStripe(String(process.env.REACT_APP_STRIPE_PUBLIC_API_KEY), {
          stripeAccount: accountId,
        })
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res)
      }
    }
    fetchStripeObject()
  }, [accountId])

  // If no Stripe object, do not render the Stripe Element.
  if (!stripeObject) {
    return <p>Loading...</p>
  }

  // Once we have the Stripe object, load everything.
  return <Elements stripe={stripeObject}>{children}</Elements>
}

const PaymentForm = ({
  order,
  stripeAccountId,
  setDiscounts,
  discounts,
  activity,
  handleBack,
}: Props) => {
  const { t } = useTranslation("bookingPayment")
  const { widget, isSingleActivity } = useWidget()
  const { responseLogin, triggerGoogle, logOutGoogle, loadingTrigger } = useLoginGoogle()
  const [totalPriceWithDiscount, setTotalPriceWithDiscount] = useState<number | undefined>()
  const [discountCode, setDiscountCode] = useState<string>("")
  const [paymentStatus, setPaymentStatus] = useState<"error" | "success">()
  const [visitorInfos, setVisitorInfos] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    is_sign_in_with_google: false,
  })
  useEffect(() => {
    const style = document.createElement("style")
    style.innerHTML = `
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px ${widget?.style?.colors?.activityCardBackgroundColor} inset !important;
          -webkit-text-fill-color:${widget?.style?.colors?.activityCardTextColor} !important;
          }
        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-color:${widget?.style?.colors?.actionBtnBackgroundColor} !important;
        }
      `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [widget])

  const extraInfos = useMemo(() => (widget ? widget.extraInfos : []), [widget]) as ExtraInfosType
  const totalPrice =
    activity.informations.bookingSettings.type === ActivityTypeEnum.GROUP
      ? order.bookings.reduce((acc, booking) => acc + booking.price, 0)
      : order.bookings.reduce((acc, booking) => acc + booking.price * booking.numberOfUnit, 0)

  const getInitialBookingValues = () => {
    const extraInfosAnswers = isArrayEmpty(extraInfos)
      ? []
      : extraInfos?.map((question) => ({ question, answer: "" }))
    return {
      name: "",
      email: "",
      phoneNumber: "",
      is_sign_in_with_google: false,
      promotionCode: "",
      extraInfos: extraInfosAnswers,
      checkboxes: [],
    }
  }

  const {
    control,
    handleSubmit,
    getValues,
    trigger,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: zodResolver(schemaWithTranslation(t)),
    defaultValues: getInitialBookingValues(),
  })

  useEffect(() => {
    calculatePriceWithDiscount()
  }, [discounts])

  const calculatePriceWithDiscount = async () => {
    if (discounts && discounts.length > 0) {
      let priceWithDiscount = 0
      for (const activity of order.selected_activities) {
        const discount = discounts.find((discount) => discount.activityId === activity.id)
        const activityBookings = order.bookings.filter(
          (booking) => booking.activity_id === activity.id,
        )
        const priceForOrder = await getBookingsPrice(activity.id, activityBookings, discount?.code)
        priceWithDiscount += priceForOrder.price
      }
      setTotalPriceWithDiscount(priceWithDiscount)
    }
  }

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setVisitorInfos({
        fullName: getValues("name"),
        email: getValues("email"),
        phoneNumber: getValues("phoneNumber"),
        is_sign_in_with_google: getValues("is_sign_in_with_google"),
      })
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    setValue("phoneNumber", "")
    if (responseLogin) {
      setValue("name", responseLogin.name)
      setValue("email", responseLogin.email)
      setValue("is_sign_in_with_google", true)
    } else {
      setValue("name", "")
      setValue("email", "")
      setValue("is_sign_in_with_google", false)
    }
  }, [responseLogin])

  if (paymentStatus === "success") {
    return (
      <Column mobile="margin-top: 30px;" width="100%" center alignCenter>
        <PaymentSuccessfull visitorInfos={visitorInfos} />
      </Column>
    )
  }

  const isTotalPriceWithDiscountZero =
    isNumber(totalPriceWithDiscount) && totalPriceWithDiscount === 0

  return (
    <div
      onSubmit={handleSubmit(() => {})}
      className="payment-form"
      style={{
        backgroundColor: widget?.style?.colors?.activityCardBackgroundColor,
        color: widget?.style?.colors?.activityCardTextColor,
        margin: isSingleActivity ? "1rem 3rem" : "0",
        justifySelf: isSingleActivity ? "stretch" : "center",
        justifyContent: isSingleActivity ? "stretch" : "center",
      }}
    >
      {responseLogin ? (
        <Fragment>
          <Typography>{t("successGoogleLogin", { name: responseLogin.name })}</Typography>
          <Button
            onClick={logOutGoogle}
            style={{
              color: widget.style?.colors?.actionBtnTextColor,
              backgroundColor: widget.style?.colors?.actionBtnBackgroundColor,
              width: "100%",
            }}
            margin="20px 0 0"
          >
            {t("signOutGoogle")}
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Button
            onClick={triggerGoogle}
            style={{
              color: widget.style?.colors?.actionBtnTextColor,
              backgroundColor: widget.style?.colors?.actionBtnBackgroundColor,
              width: "100%",
            }}
            margin="0 0 20px"
            disabled={loadingTrigger}
          >
            <GoogleIcon />
            {t("signInGoogle")}
          </Button>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("form.name.label")}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
                margin="normal"
                {...field}
                required
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("form.email.label")}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
                margin="normal"
                {...field}
                required
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                label={t("form.phoneNumber.label")}
                variant="outlined"
                error={!!error}
                helperText={error ? error.message : null}
                fullWidth
                margin="normal"
                {...field}
                required
              />
            )}
          />
        </Fragment>
      )}
      {!isArrayEmpty(extraInfos) && (
        <div className="extra-infos-container">
          <span className="extra-infos-title">{t("extraInfosTitle")}</span>
          {extraInfos?.map((question, index) => (
            <Controller
              key={index}
              name={`extraInfos.${index}.answer`}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  required
                  label={capitalize(question)}
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  helperText={error ? error.message : null}
                  error={!!error}
                />
              )}
            />
          ))}
        </div>
      )}

      <Discount
        setDiscounts={setDiscounts}
        order={order}
        discountCode={discountCode}
        setDiscountCode={setDiscountCode}
      />
      <Title size="22px" margin="10px 0" width="100%">
        Total:{" "}
        {isNumber(totalPriceWithDiscount) ? (
          <>
            <span style={{ textDecorationLine: "line-through", margin: "0 10px" }}>
              {totalPrice.toFixed(2)}€
            </span>
            <span>{totalPriceWithDiscount.toFixed(2)}€</span>
          </>
        ) : (
          <span style={{ marginLeft: "10px" }}>{totalPrice.toFixed(2)}€</span>
        )}
      </Title>
      <StripeWrapper accountId={stripeAccountId}>
        <StripePayment
          t={t}
          bookings={order.bookings}
          visitorInfos={visitorInfos}
          triggerFormValidation={trigger as any}
          discountCode={discountCode}
          handleBack={handleBack}
          paymentStatus={paymentStatus}
          setPaymentStatus={setPaymentStatus}
          isPaymentRequired={!isTotalPriceWithDiscountZero}
          extraInfos={getValues("extraInfos")}
          control={control}
          errors={errors}
          setValue={setValue}
          isFormValid={isValid}
        />
      </StripeWrapper>
    </div>
  )
}

export default PaymentForm
