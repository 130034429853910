import { useCallback, useEffect, useState } from "react"
import { MenuItem } from "@mui/material"
import Cookies from "js-cookie"

import { LangugageSelectorContainer } from "./style"
import frFlag from "images/fr.png"
import enFlag from "images/en.png"
import esFlag from "images/es.png"

export default function LanguageSelector() {
  const [language, setLanguage] = useState("fr")

  const changeLanguage = useCallback((langCode: string) => {
    if (window.google && window.google.translate) {
      const selectElement = document.querySelector(".goog-te-combo") as HTMLSelectElement
      if (selectElement) {
        selectElement.value = langCode
        selectElement.dispatchEvent(new Event("change"))
        Cookies.set("googtrans", `/auto/${langCode}`)
      }
    }
  }, [])

  useEffect(() => {
    Cookies.set("googtrans", `/auto/${language}`)
  }, [])

  useEffect(() => {
    changeLanguage(language)
  }, [language])

  return (
    <LangugageSelectorContainer
      value={language}
      // @ts-ignore
      onChange={(e) => setLanguage(e.target.value)}
      label="language"
    >
      <MenuItem value={"fr"}>
        <img src={frFlag} alt="fr" style={{ width: 30 }} />
      </MenuItem>
      <MenuItem value={"en"}>
        <img src={enFlag} alt="en" style={{ width: 30 }} />
      </MenuItem>
      <MenuItem value={"es"}>
        <img src={esFlag} alt="es" style={{ width: 30 }} />
      </MenuItem>
    </LangugageSelectorContainer>
  )
}
