import { PLATFORM_LOAD_SUCCESS } from "constants/platformConstants"
import { PlatformType } from "types/widget.type"

export function platformReducer(state = {} as PlatformType, action: any) {
  const { type, payload } = action
  switch (type) {
    case PLATFORM_LOAD_SUCCESS:
      return payload as PlatformType

    default:
      return state
  }
}
