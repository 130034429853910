import React, { useCallback, useEffect } from "react"
import { jwtDecode } from "jwt-decode"

declare global {
  interface Window {
    google: any
  }
}

interface CredentialResponse {
  credential: string
}

interface JWTGoogle {
  iss: string
  azp: string
  aud: string
  sub: string
  email: string
  email_verified: boolean
  nbf: number
  name: string
  picture: string
  given_name: string
  family_name: string
  iat: number
  exp: number
  jti: string
}

export default function useLoginGoogle() {
  const [loading, setLoading] = React.useState(false)
  const [response, setResponse] = React.useState<JWTGoogle | null>(null)
  const handleCredentialResponse = useCallback((res: CredentialResponse) => {
    if (res) {
      setResponse(jwtDecode(res.credential))
    }
  }, [])

  const triggerGoogle = useCallback(async () => {
    setLoading(true)
    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
    await window.google?.accounts.id.prompt()
    setLoading(false)
  }, [])

  const logOutGoogle = useCallback(() => {
    document.cookie = `g_state=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT`
    setResponse(null)
  }, [])

  useEffect(() => {
    window.google?.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_SECRET_ID,
      callback: handleCredentialResponse,
      use_fedcm_for_prompt: false,
    })
  }, [handleCredentialResponse])

  return {
    logOutGoogle,
    triggerGoogle,
    loadingTrigger: loading,
    responseLogin: response,
  }
}
