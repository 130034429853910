import React, { Dispatch, SetStateAction } from "react"
import dayjs from "dayjs"

import { TimePickerContainer, TimeSlotButton } from "./style"
import { Title, Column, Text } from "styled_components"
import { BookingDate } from "widget/DateTicketPicker"
import { Availability } from "types/activity.type"
import { Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ActivitySpecialSlotTypesEnum, ActivityTypeHours } from "helpers/constants"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { Activity } from "backend/api/activities"
import { combineDateAndTime } from "helpers/helpers"

interface Props {
  availabilities?: Availability[]
  newBookingDate: BookingDate
  setNewBookingDate: Dispatch<SetStateAction<BookingDate>>
  setIsTimeSelected: Dispatch<SetStateAction<boolean>>
  isAvailabilitiesLoading: boolean
  DoesSelectedDayHasValidTimeslots: boolean
  currentActivityToBook: Activity
}

const BookingTimePicker = ({
  availabilities,
  newBookingDate,
  setNewBookingDate,
  setIsTimeSelected,
  isAvailabilitiesLoading,
  DoesSelectedDayHasValidTimeslots,
  currentActivityToBook,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors)

  const handleSelectSlot = (availability: Availability) => {
    try {
      const baseDate = dayjs(newBookingDate.from)
      const startTime = dayjs(availability.startTime, "HH:mm")
      const fromDateTime = baseDate.hour(startTime.hour()).minute(startTime.minute()).second(0)
      const endTime = dayjs(availability.endTime, "HH:mm")
      const toDateTime = baseDate.hour(endTime.hour()).minute(endTime.minute()).second(0)

      setNewBookingDate((prevBookingInfo) => ({
        ...prevBookingInfo,
        from: fromDateTime.toISOString(),
        to: toDateTime.toISOString(),
        maxCapacity: Number(availability.maxCapacity) - Number(availability.numberOfUnitBooked),
      }))
      setIsTimeSelected(true)
    } catch (e) {
      console.error(e)
    }
  }

  const now = dayjs().tz("Europe/Paris")

  if (isAvailabilitiesLoading) {
    return (
      <TimePickerContainer>
        <Skeleton sx={{ width: "100%" }} />
        <Skeleton sx={{ width: "100%" }} />
        <Skeleton sx={{ width: "100%" }} />
        <Skeleton sx={{ width: "100%" }} />
      </TimePickerContainer>
    )
  }

  if (!availabilities || availabilities.length === 0) {
    return (
      <TimePickerContainer style={{ color: colors?.textColorOnBackground }}>
        {t("noAvailableSlot")}
      </TimePickerContainer>
    )
  }

  const filteredAvailabilities = availabilities.filter((availability) => {
    const slotStartTime = combineDateAndTime(newBookingDate.from, availability.startTime)
    const slotEndTime = combineDateAndTime(newBookingDate.to, availability.endTime)

    const isASpecialSlotWhichRenderSlotUnavailable =
      availability.specialSlotType === ActivitySpecialSlotTypesEnum.MARK_AS_FULL ||
      availability.specialSlotType === ActivitySpecialSlotTypesEnum.UNAVAILABLE

    if (currentActivityToBook?.hours.typeHours === ActivityTypeHours.HOURS_OPERATIONS) {
      return slotEndTime.isAfter(now) && !isASpecialSlotWhichRenderSlotUnavailable
    }
    return slotStartTime.isAfter(now) && !isASpecialSlotWhichRenderSlotUnavailable
  })

  if (!filteredAvailabilities.length) {
    return (
      <TimePickerContainer>
        <Title style={{ color: colors?.textColorOnBackground }} mobile="margin-bottom: 20px">
          {t("noAvailableSlots")}
        </Title>
      </TimePickerContainer>
    )
  }

  return (
    <TimePickerContainer>
      <Title mobile="margin-bottom: 20px" style={{ color: colors?.textColorOnBackground }}>
        {DoesSelectedDayHasValidTimeslots ? t("selectSlot") : t("noAvailableSlots")}
      </Title>
      {filteredAvailabilities.map((availability, index) => {
        const availablePlaces =
          Number(availability.maxCapacity) - Number(availability.numberOfUnitBooked)
        const isSlotAvailable = availablePlaces > 0

        if (!isSlotAvailable) {
          return <></>
        }

        const isSelected =
          dayjs(availability.startTime, "HH:mm").format("HH:mm") ===
          dayjs(newBookingDate.from).format("HH:mm")
        return (
          <Column width="100%" key={index}>
            <TimeSlotButton
              secondary={!isSelected}
              style={{
                backgroundColor: colors?.activityCardBackgroundColor,
                color: colors?.activityCardTextColor,
              }}
              onClick={() => handleSelectSlot(availability)}
            >
              {dayjs(availability.startTime, "HH:mm").format("HH[h]mm")} -{" "}
              {dayjs(availability.endTime, "HH:mm").format("HH[h]mm")}
              <Text
                white={isSelected}
                size="12px"
                style={{
                  color: colors?.activityCardTextColor,
                }}
              >
                | {availability.maxUnitsPerGroup ? t("availableGroups") : t("availablePlaces")}:{" "}
                {availablePlaces}
              </Text>
            </TimeSlotButton>
          </Column>
        )
      })}
    </TimePickerContainer>
  )
}

export default BookingTimePicker
