import { useMemo } from "react"
import { useSelector } from "react-redux"
import { isNumber } from "lodash"
import { RootState } from "store"

const useWidget = () => {
  const widget = useSelector((state: RootState) => state?.widget)

  const { colors, isSingleActivity } = useMemo(() => {
    const colors = widget?.style?.colors ?? null
    const isSingleActivity = isNumber(widget?.platformId)
    return { colors, isSingleActivity }
  }, [widget])

  return { widget, colors, isSingleActivity }
}

export default useWidget
