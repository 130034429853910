import React from "react"
import { Button, Row, Title } from "styled_components"
import { BookingResume } from "components"

import { Order } from "types/order.type"
import { Step } from "widget"
import PaymentForm from "components/PaymentForm"
import { useTranslation } from "react-i18next"
import { Discount } from "backend/api/discounts"
import { Activity } from "backend/api/activities"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import PinnedDiscountCard from "components/PinnedDiscountCard"
import { WidgetType } from "types/widget.type"
import useWidget from "hooks/useWidget"

interface Props {
  order: Order
  stripeAccountId: string
  activity: Activity
  handleChangeStep: (step: Step) => void
  pinnedDiscount?: WidgetType
}

const BookingPayment = ({
  order,
  stripeAccountId,
  activity,
  handleChangeStep,
  pinnedDiscount,
}: Props) => {
  const { t } = useTranslation("bookingPayment")
  const [discounts, setDiscounts] = React.useState<Discount[]>([])
  const { colors, isSingleActivity } = useWidget()

  const handleBack = () => {
    handleChangeStep("dateTicketPicker")
  }
  return (
    <div style={{ padding: isSingleActivity ? "0px" : "20px 10px" }}>
      {!isSingleActivity && (
        <Row space width="100%">
          <Button
            secondary
            onClick={handleBack}
            style={{
              backgroundColor: colors?.actionBtnBackgroundColor,
              color: colors?.actionBtnTextColor,
            }}
          >
            <ArrowBackIcon
              style={{
                color: colors?.actionBtnTextColor,
                fontSize: "25px",
                marginRight: "4px",
              }}
            />
            {t("previous")}
          </Button>
        </Row>
      )}
      <PinnedDiscountCard discount={pinnedDiscount} />

      {!isSingleActivity && (
        <Row
          width="100%"
          margin="20px 0"
          space
          mobile="flex-direction: column; margin-top: 20px; margin: 20px 0 30px 0"
        >
          <Title
            style={{
              color: colors?.textColorOnBackground,
            }}
          >
            {t("bookingInProgress")}
          </Title>
          <Title
            mobile="font-size: 14px; margin-top: 5px;"
            grey
            style={{
              color: colors?.textColorOnBackground,
            }}
          >
            {t("ticketSelection")}
          </Title>
        </Row>
      )}

      <BookingResume order={order} activity={activity} discounts={discounts} />
      <PaymentForm
        order={order}
        stripeAccountId={stripeAccountId}
        setDiscounts={setDiscounts}
        discounts={discounts}
        activity={activity}
        handleBack={handleBack}
      />
    </div>
  )
}

export default BookingPayment
