import React, { Dispatch, SetStateAction } from "react"

import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined"

import { Activity } from "backend/api/activities"
import { NextStepFixedButton } from "./style"
import { Order } from "types/order.type"
import { Step } from "widget"
import { Row, Title } from "styled_components"
import { ActivityCard } from "components"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WidgetColorsType, WidgetType } from "types/widget.type"
import "./ActivityList.css"
import PinnedDiscountCard from "components/PinnedDiscountCard"

interface Props {
  activities: Activity[]
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
  handleChangeStep: (step: Step) => void
  pinnedDiscount?: WidgetType
}

const ActivityList = ({ activities, order, setOrder, handleChangeStep, pinnedDiscount }: Props) => {
  const { t } = useTranslation("activityList")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const activityCount = activities.filter((activity) => {
    const isActivityFinish =
      activity.hours.dates?.to && new Date(activity.hours.dates?.to || "") < new Date()
    return !isActivityFinish
  }).length

  /* MANY ACTIVITIES SELECTION 
  const handleSelectActivity = (activity: Activity, isAlreadySelected: boolean) => {
    setOrder((prevOrder) => {
      return {
        ...prevOrder,
        selected_activities: isAlreadySelected
          ? prevOrder.selected_activities.filter((a) => a.id !== activity.id)
          : [...prevOrder.selected_activities, activity],
      }
    })
  }
  */

  /* ONLY ONE ACTIVITY SELECTION  */
  const handleSelectActivity = (activity: Activity, isAlreadySelected: boolean) => {
    setOrder({
      ...order,
      selected_activities: [activity],
    })
    handleChangeStep("dateTicketPicker")
  }

  return (
    <div className="activity-list-container">
      <Row margin="0 0 30px 0" space mobile="flex-direction: column;">
        <Title
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {activityCount} {t("availableActivities")}
        </Title>
        <Title
          mobile="font-size: 14px; margin-top: 5px;"
          grey
          style={{
            color: colors?.textColorOnBackground,
          }}
        >
          {t("selectActivities")}
        </Title>
      </Row>
      <PinnedDiscountCard discount={pinnedDiscount} />
      <div className="activity-cards-container">
        {activities.map((activity) => (
          <ActivityCard
            isSelected={order.selected_activities.some(
              (selectedActivity) => selectedActivity.id === activity.id,
            )}
            handleSelect={handleSelectActivity}
            handleChangeStep={handleChangeStep}
            key={activity.id}
            activity={activity}
          />
        ))}
      </div>
      {order.selected_activities.length > 0 && (
        <NextStepFixedButton onClick={() => handleChangeStep("dateTicketPicker")}>
          <ConfirmationNumberOutlinedIcon
            style={{ color: "white", fontSize: "25px", marginRight: "4px" }}
          />
          {t("validateReservation")}
        </NextStepFixedButton>
      )}
    </div>
  )
}

export default ActivityList
