import { widgetReducer } from "reducers/widgetReducer"
import { createStore, combineReducers } from "redux"
import { composeWithDevTools } from "@redux-devtools/extension"
import { clientReducer } from "reducers/clientReducer"
import { platformReducer } from "reducers/platformReducer"

const reducer = combineReducers({
  widget: widgetReducer,
  client: clientReducer,
  singleActivityPlatform: platformReducer,
})
const initialState = {}
const store = createStore(reducer, initialState, composeWithDevTools())

export default store

export type RootState = ReturnType<typeof reducer>
