import React, { Dispatch, SetStateAction, useEffect } from "react"
import { Title } from "styled_components"
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import "dayjs/locale/fr"
import { BookingDate } from "widget/DateTicketPicker"
import { useTranslation } from "react-i18next"
import "./BookingDatePicker.css"
import { useSelector } from "react-redux"
import { RootState } from "store"
import dayjs, { Dayjs } from "dayjs"
import { defaultBackgroundColor, defaultTextColor } from "components/general/constants"

interface Props {
  newBookingDate: BookingDate
  setNewBookingDate: Dispatch<SetStateAction<BookingDate>>
  setIsTimeSelected: Dispatch<SetStateAction<boolean>>
  setSelectedDay: Dispatch<SetStateAction<Date>>
  shouldDisableDate: (date: Dayjs) => boolean
  isCurrentDateSelectedEnabled: boolean
}

const BookingDatePicker = ({
  newBookingDate,
  setNewBookingDate,
  shouldDisableDate,
  setIsTimeSelected,
  setSelectedDay,
  isCurrentDateSelectedEnabled,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors)

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const selectedDate = new Date(Date.UTC(date.year(), date.month(), date.date()))

      setSelectedDay(selectedDate)
      setNewBookingDate({
        ...newBookingDate,
        from: selectedDate.toISOString(),
        to: selectedDate.toISOString(),
      })
      setIsTimeSelected(false)
    }
  }

  useEffect(() => {
    const style = document.createElement("style")

    style.innerHTML = `
      .booking-date-picker *{
          color:${colors?.activityCardTextColor} !important;
        }
      .booking-date-picker .css-3bhf6e-MuiButtonBase-root-MuiPickersDay-root {
        font-weight: 700;
      }

      .booking-date-picker .Mui-disabled {
        text-decoration: line-through;
        opacity:50%;
      }        
      .booking-date-picker .Mui-selected:not(.MuiPickersDay-today) {
        color:${colors?.actionBtnTextColor} !important;
        background-color:${colors?.actionBtnBackgroundColor} !important;
        font-weight: 700;
      }
      .booking-date-picker .MuiPickersDay-today {
        text-decoration: none;
        color:${isCurrentDateSelectedEnabled ? (colors?.actionBtnTextColor ?? defaultTextColor) : ""} !important;
        background-color:${isCurrentDateSelectedEnabled ? (colors?.actionBtnBackgroundColor ?? defaultBackgroundColor) : ""} !important;
        font-weight: 700;
      }
      `
    document.head.appendChild(style)

    return () => {
      document.head.removeChild(style)
    }
  }, [isCurrentDateSelectedEnabled])

  return (
    <div
      className="booking-date-picker"
      style={{ backgroundColor: colors?.activityCardBackgroundColor }}
    >
      <Title style={{ color: colors?.activityCardTextColor }}>{t("selectDate")}</Title>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <DateCalendar
          sx={{ margin: "0" }}
          shouldDisableDate={shouldDisableDate}
          onChange={handleDateChange}
          onMonthChange={handleDateChange}
        />
      </LocalizationProvider>
    </div>
  )
}

export default BookingDatePicker
