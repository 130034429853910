import { isArrayEmpty } from "helpers/helpers"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { RootState } from "store"
import { WidgetColorsType, WidgetType } from "types/widget.type"
import "./PinnedDiscountCard.css"

interface Props {
  discount?: WidgetType
}

const PinnedDiscountCard = ({ discount }: Props) => {
  const { t } = useTranslation("pinnedDiscount")
  const colors = useSelector((state: RootState) => state.widget?.style?.colors) as WidgetColorsType
  const activityTitles = isArrayEmpty(discount?.activities) ? "" : discount?.activities.map(activity => activity.title).join(', ').toLowerCase();
  return (
    <div className="pinned-container">
      {discount?.activity_discounts && (
        <div className="pinned-discount-card" style={{ backgroundColor: colors?.actionBtnBackgroundColor }}>
          <p className="discount-info" style={{ color: colors?.actionBtnTextColor }}>
            {discount.activity_discounts.lastDayDiscount && (
              <span>{t("lastChance")}: </span>
            )}
            {(discount.activity_discounts.startDate && discount.activity_discounts.endDate) && (
              <span>{t("from")} {moment(discount.activity_discounts.startDate).format("DD/MM/YYYY")} {t("to")} {moment(discount.activity_discounts.endDate).format("DD/MM/YYYY")}, </span>
            )}
            {discount.activity_discounts.minBasket && (
              <span>{discount.activity_discounts.startDate ? t("minBasket", { amount: discount.activity_discounts.minBasket }).toLowerCase() : t("minBasket", { amount: discount.activity_discounts.minBasket })}, </span>
            )}
            {discount.activity_discounts.startDate || discount.activity_discounts.minBasket ? t("enjoyDiscount", { discount: discount.activity_discounts.discountNumber, activityName: activityTitles }).toLowerCase() : t("enjoyDiscount", { discount: discount.activity_discounts.discountNumber, activityName: activityTitles })}
            {(discount.activity_discounts.maxUseAmount || discount.activity_discounts.maxUseTickets) && (
              <span> {t("limitedOffer", { maxUseTickets: discount.activity_discounts.maxUseTickets })}</span>
            )}
            {" "}
            {t("useCode")}
            <strong> {discount.widgets.discountCode}</strong>
          </p>
        </div>
      )}
    </div>
  )
}

export default PinnedDiscountCard
