import React from "react"
import { useTranslation } from "react-i18next"
import successImg from "images/success_payment.png"
import { arePropsEqual } from "helpers/helpers"
import "./PaymentSuccessfull.css"
import useWidget from "hooks/useWidget"

interface Props {
  visitorInfos: {
    fullName: string
    email: string
    phoneNumber: string
  }
}

const PaymentSuccessfull = ({ visitorInfos }: Props) => {
  const { t } = useTranslation("bookingPayment")
  const { colors, isSingleActivity } = useWidget()

  return (
    <div
      style={{
        backgroundColor: colors?.activityCardBackgroundColor,
        color: colors?.activityCardTextColor,
        margin: isSingleActivity ? "0 3rem" : "",
        alignSelf: isSingleActivity ? "stretch" : "center",
      }}
      className="payment-successfull"
    >
      <img src={successImg} style={{ width: "300px", maxWidth: "90vw", marginBottom: "35px" }} />
      <strong>{t("paymentSuccessfull.title", { name: visitorInfos.fullName })}</strong>
      <span>{t("paymentSuccessfull.description", { email: visitorInfos.email })}</span>
    </div>
  )
}

export default React.memo(PaymentSuccessfull, arePropsEqual)
