import styled from "styled-components"

// Define the props interface
interface WidgetContainerProps {
  fontFamily?: string
}

// Create the styled component with props
export const WidgetContainer = styled.div<WidgetContainerProps>`
  overflow-y: scroll;
  position: relative;
  border: #829695;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 #bdbdbd;
  font-family: ${(props) => props.fontFamily};

  /* Ensure all child elements also use the font */
  &,
  * {
    font-family: ${(props) => props.fontFamily} !important;
    font {
      box-shadow: none;
    }
  }
`

export const defaultWidgetColors = {
  widgetBackgroundColor: "#FFF",
  textColorOnBackground: "#000",
  activityCardBackgroundColor: "#FFF",
  activityCardTextColor: "#000",
  actionBtnBackgroundColor: "#FFF",
  actionBtnTextColor: "#064643",
}
