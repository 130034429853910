import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"

import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"

import { Activity, getAvaibilities } from "backend/api/activities"
import { Step } from "widget"
import { Order } from "types/order.type"
import { BookingItem } from "backend/api/bookings"
import { ActivitySpecialSlotTypesEnum, DATEFORMAT, theme } from "helpers/constants"
import { NextStepFixedButton } from "./style"
import { ActivityCard, BookingTicketPicker, BookingTimePicker, BookingDatePicker } from "components"
import { Button, Row, Title } from "styled_components"
import { useQuery } from "react-query"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import "./DateTicketPicker.css"
import { WidgetType } from "types/widget.type"
import PinnedDiscountCard from "components/PinnedDiscountCard"
import { checkIfToday, getMonthEnd, getMonthStart } from "helpers/helpers"
import useWidget from "hooks/useWidget"

export interface BookingDate {
  from: string
  to: string
  maxCapacity: number
}

interface Props {
  selectedActivies: Activity[]
  handleChangeStep: (step: Step) => void
  order: Order
  setOrder: Dispatch<SetStateAction<Order>>
  pinnedDiscount?: WidgetType
}

const DateTicketPicker = ({
  selectedActivies,
  handleChangeStep,
  order,
  setOrder,
  pinnedDiscount,
}: Props) => {
  const { t } = useTranslation("dateTicketPicker")
  const { colors, isSingleActivity } = useWidget()
  const [currentActivityToBook, setCurrentActivityToBook] = useState<Activity>(selectedActivies[0])
  const [bookingStep, setBookingStep] = useState<"datePicker" | "ticketPicker">("datePicker")
  const [isTimeSlected, setIsTimeSelected] = useState<boolean>(false)
  const [selectedDay, setSelectedDay] = useState<Date>(new Date())
  const [isCurrentDateEnabled, setIsCurrentDateEnabled] = useState(false)
  const [newBookingDate, setNewBookingDate] = useState<BookingDate>({
    from: new Date().toISOString(),
    to: new Date().toISOString(),
    maxCapacity: 0,
  })
  const [newBookingInformations, setNewBookingInformations] = useState<BookingItem[]>([])

  const parseDateToFetch = (date: string) => new Date(date).toISOString().split("T")[0] || date

  const {
    data: activityAvailability,
    refetch: reftechAvailabities,
    isLoading: isAvailabilitiesLoading,
  } = useQuery({
    queryKey: [`${currentActivityToBook.id}-availability-${selectedDay}`],
    enabled: !!newBookingDate.from && !!newBookingDate.to,
    queryFn: () =>
      getAvaibilities(
        currentActivityToBook.id,
        parseDateToFetch(newBookingDate.from),
        parseDateToFetch(newBookingDate.to),
      ),
    refetchOnWindowFocus: false,
  })

  const currentMonth = useMemo(() => getMonthStart(selectedDay), [selectedDay])

  const { data: activityAvailabilityOnMonth } = useQuery({
    queryKey: [`${currentActivityToBook.id}-month-availability-${selectedDay.getMonth()}`],
    queryFn: () => {
      const startOfMonth = getMonthStart(currentMonth, DATEFORMAT.DAY)
      const endOfMonth = getMonthEnd(currentMonth, DATEFORMAT.DAY)
      return getAvaibilities(currentActivityToBook.id, startOfMonth, endOfMonth)
    },
    refetchOnWindowFocus: false,
  })

  const handleBack = () => {
    if (bookingStep === "ticketPicker") {
      setBookingStep("datePicker")
    } else {
      const currentActivityToBookIndex = selectedActivies.findIndex(
        (activity) => activity.id === currentActivityToBook.id,
      )
      if (currentActivityToBookIndex === 0) {
        // reset all bookings if go to activity list
        setOrder((prevOrder) => {
          return {
            ...prevOrder,
            selected_activities: [],
          }
        })
        handleChangeStep("activityList")
      } else {
        // reset previous booking if go to previous activity ticket picker
        setOrder((prevOrder) => {
          return {
            ...prevOrder,
            bookings: prevOrder.bookings.filter(
              (booking, index) => booking.activity_id !== currentActivityToBook.id,
            ),
          }
        })
        setCurrentActivityToBook(selectedActivies[currentActivityToBookIndex - 1])
      }
    }
  }

  const handleNext = (newBookingInformationsParams: BookingItem[] = newBookingInformations) => {
    setIsTimeSelected(false)

    if (bookingStep === "datePicker") {
      setBookingStep("ticketPicker")
    }

    if (bookingStep === "ticketPicker" && newBookingInformationsParams) {
      setOrder((prevOrder) => {
        return {
          ...prevOrder,
          bookings: newBookingInformationsParams,
        }
      })

      const currentActivityToBookIndex = selectedActivies.findIndex(
        (activity) => activity.id === currentActivityToBook.id,
      )

      const isLastActivityToBook = currentActivityToBookIndex === selectedActivies.length - 1

      // reset states
      setBookingStep("datePicker")
      setNewBookingDate({ from: "", to: "", maxCapacity: 0 })

      if (isLastActivityToBook) {
        setNewBookingInformations([])
        handleChangeStep("payment")
      } else {
        setCurrentActivityToBook(selectedActivies[currentActivityToBookIndex + 1])
      }
    }
  }

  /* TO AUTOMATICALY CONFIRM AT TIME SELECTION */
  useEffect(() => {
    if (isTimeSlected) {
      handleNext(newBookingInformations)
    }
  }, [isTimeSlected])

  const enabledDays = useMemo(() => {
    const now = dayjs()
    return activityAvailabilityOnMonth
      ?.filter((item) => {
        const hasValidSlot = item.availabilities.some((availability: any) => {
          const availabilityDateTime = dayjs(
            `${item.date} ${availability.startTime}`,
            DATEFORMAT.DATE_TIME,
          )

          const isSpecialSlotUnavailable =
            availability.specialSlotType === ActivitySpecialSlotTypesEnum.MARK_AS_FULL ||
            availability.specialSlotType === ActivitySpecialSlotTypesEnum.UNAVAILABLE
          const isActivityFullyBooked =
            Number(availability.maxCapacity) === Number(availability.numberOfUnitBooked)
          const isDayEnabled =
            !isSpecialSlotUnavailable && availabilityDateTime.isAfter(now) && !isActivityFullyBooked

          if (isDayEnabled && checkIfToday(item.date)) {
            setIsCurrentDateEnabled(true)
          }

          return isDayEnabled
        })

        return hasValidSlot
      })
      .map((item) => item.date)
  }, [activityAvailabilityOnMonth])

  const shouldDisableDate = (date: dayjs.Dayjs) => {
    const minDate = dayjs(currentActivityToBook.hours.dates?.from || new Date())
    return !enabledDays?.includes(date.format(DATEFORMAT.DAY)) || date.isBefore(minDate, "day")
  }

  const isCurrentDateSelectedEnabled = useMemo(
    () => checkIfToday(selectedDay) && isCurrentDateEnabled,
    [isCurrentDateEnabled, selectedDay],
  )
  const DoesSelectedDayHasValidTimeslots = useMemo(
    () => !!enabledDays?.some((enabledDay) => dayjs(enabledDay).isSame(selectedDay, "day")),
    [enabledDays, selectedDay],
  )

  return (
    <div style={{ padding: isSingleActivity ? "0" : "20px 10px" }}>
      <Row space width="100%">
        {!isSingleActivity && (
          <Button
            secondary
            onClick={handleBack}
            style={{
              color: colors?.actionBtnTextColor,
              backgroundColor: colors?.actionBtnBackgroundColor,
            }}
          >
            <ArrowBackIcon
              style={{
                color: colors?.actionBtnTextColor ?? theme.color.primary,
                fontSize: "25px",
                marginRight: "4px",
              }}
            />
            {t("previous")}
          </Button>
        )}
        {isTimeSlected && bookingStep === "datePicker" && !isSingleActivity && (
          <Button
            onClick={() => handleNext(newBookingInformations)}
            style={{
              color: colors?.actionBtnTextColor,
              backgroundColor: colors?.actionBtnBackgroundColor,
            }}
          >
            {t("next")}{" "}
            <ArrowForwardIcon
              style={{ color: colors?.actionBtnTextColor ?? "white", fontSize: "25px" }}
            />
          </Button>
        )}
      </Row>
      <PinnedDiscountCard discount={pinnedDiscount} />

      {!isSingleActivity && (
        <Row
          width="100%"
          margin="20px 0"
          space
          mobile="flex-direction: column; margin-top: 20px; margin: 20px 0 30px 0"
        >
          <Title
            style={{
              color: colors?.textColorOnBackground,
            }}
          >
            {t("bookingInProgress")}
          </Title>
          <Title
            mobile="font-size: 14px; margin-top: 5px;"
            grey
            style={{
              color: colors?.textColorOnBackground,
            }}
          >
            {t("ticketSelection")}
          </Title>
        </Row>
      )}
      <div
        className="activity-calendar-container"
        style={{
          gridTemplateColumns:
            isSingleActivity || bookingStep === "ticketPicker" ? "100%" : "70% 1fr",
        }}
      >
        <ActivityCard
          key={currentActivityToBook.id}
          activity={currentActivityToBook}
          isCarouselActive={bookingStep === "datePicker"}
        />
        {bookingStep === "datePicker" && (
          <div className="date-picker-container">
            <BookingDatePicker
              newBookingDate={newBookingDate}
              setNewBookingDate={setNewBookingDate}
              setIsTimeSelected={setIsTimeSelected}
              setSelectedDay={setSelectedDay}
              shouldDisableDate={shouldDisableDate}
              isCurrentDateSelectedEnabled={isCurrentDateSelectedEnabled}
            />
            <BookingTimePicker
              availabilities={activityAvailability?.[0]?.availabilities}
              newBookingDate={newBookingDate}
              setNewBookingDate={setNewBookingDate}
              setIsTimeSelected={setIsTimeSelected}
              isAvailabilitiesLoading={isAvailabilitiesLoading}
              DoesSelectedDayHasValidTimeslots={DoesSelectedDayHasValidTimeslots}
              currentActivityToBook={currentActivityToBook}
            />
          </div>
        )}

        {bookingStep === "ticketPicker" && (
          <BookingTicketPicker
            activity={currentActivityToBook}
            newBookingInformations={newBookingInformations}
            setNewBookingInformations={setNewBookingInformations}
            newBookingDate={newBookingDate}
            handleNext={handleNext}
          />
        )}

        {isTimeSlected && bookingStep === "datePicker" && (
          <NextStepFixedButton onClick={() => handleNext(newBookingInformations)}>
            {t("next")} <ArrowForwardIcon style={{ color: "white", fontSize: "25px" }} />
          </NextStepFixedButton>
        )}
      </div>
    </div>
  )
}

export default DateTicketPicker
